import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getAuthHeaders,
  _getAuthToken,
  _getClientID,
  _getClientSecret,
  _getDMATokenDetails,
  _getDMTProxy,
  _getIdToken,
  _getLoggedInUserDetails,
  _getRedirectUri,
} from "../../../envUrl";
import api from "../../api";

export const dmtAssessmentApi = createApi({
  reducerPath: "dmtAssessmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getDMTProxy(),
  }),
  endpoints: (builder) => ({
    getAssessment: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();
        const userId = _getDMATokenDetails("sub");

        const payload = {
          isget: true,
          userId,
        };

        return {
          url: api.dmtAssessment,
          method: "POST",
          body: payload,
          headers: authHeaders,
        };
      },
    }),
    saveAssessment: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();
        const userId = _getDMATokenDetails("sub");

        const body = {
          ...payload,
          isget: false,
          userId,
        };

        return {
          url: api.dmtAssessment,
          method: "POST",
          body,
          headers: authHeaders,
        };
      },
    }),
    getDmaCmptdAsmt: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();
        const sub = _getDMATokenDetails("sub");

        const payload = {
          isget: true,
          userId: sub,
          isdatabyuser: "1",
        };

        return {
          url: api.dmtCompletedAssessment,
          method: "POST",
          body: payload,
          headers: authHeaders,
        };
      },
    }),
    getDmaAssessmentDtls: builder.mutation({
      query: (asmtId) => {
        const authHeaders = _getAuthHeaders();

        const payload = {
          isget: true,
          id: asmtId,
          isdatabyuser: "-1",
        };

        return {
          url: api.dmtCompletedAssessment,
          method: "POST",
          body: payload,
          headers: authHeaders,
        };
      },
    }),
    saveEditedQuantitative: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.dmtEditQuantitative,
          method: "POST",
          body: payload,
          headers: authHeaders,
        };
      },
    }),
  }),
});

export const {
  useGetDmaCmptdAsmtMutation,
  useGetDmaAssessmentDtlsMutation,
  useGetAssessmentMutation,
  useSaveAssessmentMutation,
  useSaveEditedQuantitativeMutation,
} = dmtAssessmentApi;
