import { useCallback, useEffect, useMemo, useState } from "react";
import "./QuestionsList.scss";
import {
  Accordion,
  AccordionItem,
  Button,
  ButtonGroup,
  Notification,
  Tab,
  Tabs,
  toaster,
} from "@appkit4/react-components";
import {
  converQuestionsTreeToJSONMap,
  convertQuestionsJsonStringToTreeMap,
  updateProgressInQuestionsTree,
} from "../../../../../utils/utilFns";
import { Questions } from "../question/Question";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  useGetDmaAssessmentDtlsMutation,
  useSaveAssessmentMutation,
} from "../../../../../store/services/dmt/assessment";
import { setLoading } from "../../../../../store/slices/dtms/loading";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import NoProjectImg from "../../../../../assets/images/no-project.svg";
import { NewNoProject } from "../../../../../components/new-noproject/NewNoProject";
import {
  setDmaAssessmentDetails,
  setIsAssessmentActive,
} from "../../../../../store/slices/dmt/assessment";
import { Report } from "../../../report/Report";

export const QuestionsList = ({
  isCompletedAsmt = false,
  getDMATemplate = () => {},
  className = "",
  questionListView = "question",
}) => {
  const completedAssessmentPath = useResolvedPath("../completedassessment");
  const params = useParams();
  const dispatch = useDispatch();

  const assessmentDetails = useSelector(
    (state) => state.dmaAssessment.assessmentDetails
  );
  const templateDetails = useSelector(
    (state) => state.dmaTemplate.templateDetails
  );
  const [questionTree, setQuestionTree] = useState();
  const [assessmentDtls, setAssessmentDtls] = useState();
  const navigate = useNavigate();
  const [getAssessmentDtls, getAssessmentDtlsResult] =
    useGetDmaAssessmentDtlsMutation();
  const [saveAssessment, saveAssessmentResult] = useSaveAssessmentMutation();

  useEffect(() => {
    if (isCompletedAsmt) {
      getAssessmentDtls(params.asmtId);
    }
  }, [getAssessmentDtls, isCompletedAsmt, params.asmtId]);

  useEffect(() => {
    if (
      !isCompletedAsmt &&
      (!_.isEmpty(assessmentDetails) || !_.isEmpty(templateDetails))
    ) {
      setQuestionTree(
        convertQuestionsJsonStringToTreeMap(
          assessmentDetails?.userResponses || templateDetails?.questionStructure
        )
      );
    }
  }, [isCompletedAsmt, assessmentDetails, templateDetails]);

  useEffect(() => {
    const { data, isSuccess, error, isError, isLoading } =
      getAssessmentDtlsResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      setAssessmentDtls(data);
      setQuestionTree(
        convertQuestionsJsonStringToTreeMap(data.userResponses, isCompletedAsmt)
      );
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to fetch module roles details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [dispatch, getAssessmentDtlsResult, isCompletedAsmt]);
  useEffect(() => {
    const { data, isSuccess, error, isError, isLoading } = saveAssessmentResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      dispatch(setDmaAssessmentDetails(data.isSubmitted ? {} : data));
      if (data.isSubmitted) {
        dispatch(setIsAssessmentActive(false));
        toaster.notify(
          <Notification
            title="Success"
            message={"Assessment submitted successfully"}
            status="success"
          />,
          { position: "bottomLeft", onClose: () => {}, duration: 5000 }
        );
        navigate(completedAssessmentPath);
      } else {
        toaster.notify(
          <Notification
            title="Success"
            message={"Assessment saved successfully"}
            status="success"
          />,
          { position: "bottomLeft", onClose: () => {}, duration: 5000 }
        );
      }
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to save assessment details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [completedAssessmentPath, dispatch, navigate, saveAssessmentResult]);

  const dimensionList = useMemo(
    () =>
      questionTree?.map((dimension) => ({
        label: `${dimension.name} (${
          isCompletedAsmt ? "" : `${dimension.completed || 0}/`
        }${dimension.children.length})`,
        value: dimension.name,
      })),
    [questionTree, isCompletedAsmt]
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  const onSelectOption = useCallback(
    ({ value, subDimensionIndex, questionIndex, type }) => {
      const path = `${currentIndex}.children[${subDimensionIndex}].children[${questionIndex}]`;
      switch (type) {
        case "MC":
          setQuestionTree((tree) => {
            _.set(tree, `${path}.responseValue`, value.id);
            updateProgressInQuestionsTree({
              questionsTree: tree,
              dimensionIndex: currentIndex,
              subDimensionIndex,
              questionIndex,
              setResponseTarget: true,
            });
            return [...tree];
          });
          break;
        case "MS":
          setQuestionTree((tree) => {
            _.set(
              tree,
              `${path}.responseValue`,
              value.map((val) => val.id)
            );
            updateProgressInQuestionsTree({
              questionsTree: tree,
              dimensionIndex: currentIndex,
              subDimensionIndex,
              questionIndex,
              setResponseTarget: true,
            });
            return [...tree];
          });
          break;
        case "SQ":
          setQuestionTree((tree) => {
            _.set(tree, `${path}.responseValue`, value);
            _.set(tree, `${path}.response`, value.current);
            _.set(tree, `${path}.responseTarget`, value.target);
            updateProgressInQuestionsTree({
              questionsTree: tree,
              dimensionIndex: currentIndex,
              subDimensionIndex,
              questionIndex,
            });
            return [...tree];
          });
          break;
        case "textarea":
          setQuestionTree((tree) => {
            _.set(tree, `${path}.other`, value);
            return [...tree];
          });
          break;
        default:
          console.log("no handlers for type", type, currentIndex, value);
      }
    },
    [currentIndex]
  );

  const onSave = useCallback(() => {
    const userResponseObj = converQuestionsTreeToJSONMap(questionTree);
    const payload = {
      ...assessmentDetails,
      userResponses: JSON.stringify(userResponseObj),
    };
    saveAssessment(payload);
  }, [questionTree, assessmentDetails, saveAssessment]);

  const onSubmit = useCallback(() => {
    const userResponseObj = converQuestionsTreeToJSONMap(questionTree);
    const payload = {
      ...assessmentDetails,
      isSubmitted: true,
      userResponses: JSON.stringify(userResponseObj),
    };
    saveAssessment(payload);
  }, [assessmentDetails, questionTree, saveAssessment]);

  const [activeKeys, setActiveKeys] = useState([]);

  const onClickAccordion = useCallback((activeKeys) => {
    setActiveKeys(activeKeys);
  }, []);

  useEffect(() => {
    setActiveKeys([]);
  }, [currentIndex]);
  const [showReport, setShowReport] = useState(false);
  useEffect(() => {
    setShowReport(questionListView === "report");
  }, [questionListView]);
  return (
    <div
      className={`questions-list-container ${
        isCompletedAsmt && "dma-completed-assessment-questions"
      }`}
    >
      <div className="questions-list-header-container">
        <div className="question-list-title">
          {isCompletedAsmt
            ? assessmentDtls?.tempName &&
              assessmentDtls?.sector &&
              `${assessmentDtls?.tempName} (${assessmentDtls?.sector})`
            : showReport
            ? "Report"
            : "Assessment"}
          {isCompletedAsmt && (
            <div className="question-list-title-description">
              {`completed at ${moment(assessmentDtls?.updatedDate).format(
                "Do MMM YYYY LTS"
              )}`}
            </div>
          )}
        </div>
        {/* <div className="progress-container">
                <Select
                    data={progressTypeList}
                    value={currentProgressType}
                    onSelect={setCurrentProgressType}
                    hideTitleOnInput
                >
                </Select>
            </div> */}
        <ButtonGroup>
          <Button
            className="view-reports-button"
            compact
            kind="text"
            onClick={() => setShowReport((val) => !val)}
          >
            {showReport ? (
              <>View Assessment</>
            ) : (
              <>
                <span className="Appkit4-icon icon-powerbi-outline"></span>View
                Report
              </>
            )}
          </Button>
          {!isCompletedAsmt && (
            <>
              {!showReport ? (
                <Button
                  className="question-list-save-button"
                  compact
                  kind="primary"
                  onClick={onSave}
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="question-list-save-button"
                  compact
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      </div>
      {showReport ? (
        <Report isCompletedAssessment={isCompletedAsmt} />
      ) : (
        <div className="questions-list-section">
          <Tabs
            className="question-list-dimentions-tabs"
            type="underline"
            // pinActiveTab
            activeIndex={currentIndex}
            onTabChange={setCurrentIndex}
            responsive
            showBothIndicators
          >
            {dimensionList?.map((dimension, ind) => (
              <Tab key={ind} label={dimension.label} value={dimension.value} />
            ))}
          </Tabs>
          <Accordion
            className="questions-list-section-accordion"
            multiple={true}
            onClick={onClickAccordion}
            activeKeys={activeKeys}
          >
            {questionTree &&
              questionTree[currentIndex]?.children?.map((subDimention, ind) => (
                <AccordionItem
                  className="questions-list-section-accordion-item"
                  showExpandIcon={false}
                  key={ind}
                  templateHeader={() => (
                    <div className="questions-accordion-heading-container">
                      <span
                        className={`Appkit4-icon icon-right-chevron-outline ${
                          activeKeys.includes(`${ind}`) ? "rotate" : ""
                        }`}
                      ></span>{" "}
                      {subDimention.name} (
                      {!isCompletedAsmt && `${subDimention.completed || 0}/`}
                      {subDimention.children?.length})
                    </div>
                  )}
                  itemKey={`${ind}`}
                >
                  <Questions
                    questions={subDimention.children}
                    onSelectOption={onSelectOption}
                    disableEdit={isCompletedAsmt}
                    subDimensionIndex={ind}
                  />
                </AccordionItem>
              ))}
          </Accordion>
          {!assessmentDetails?.userResponses &&
            !templateDetails?.questionStructure &&
            !isCompletedAsmt && (
              <NewNoProject
                imageUrl={NoProjectImg}
                showCreateButton={false}
                title={"Sorry, no questions found."}
                className="dma-ca-noresults"
              />
            )}
        </div>
      )}
    </div>
  );
};
