const api = {
  inviteUser: `/dtms/api/v1/dtms/user/invite`,
  registerUser: `/auth/api/v1/dtms/auth/registerUser`,
  getAccessCode: `/dtms/api/v1/dtms/user/accesscode`,
  getRefreshCode: `/dtms/api/v1/dtms/user/refreshcode`,
  revokeToken: `/dtms/api/v1/dtms/user/revoke`,
  shareRelease: `/dtms/api/v1/tpra/tag/investor`,
  shareReport: `/dtms/api/v1/tpra/tag/client`,
  shareReportToTp: `/dtms/api/v1/tpra/tag/report/investor`,
  getReleaseUser: `/dtms/api/v1/tpra/getReleaseUser`,
  getAllFiles: `/dtms/api/v1/tpra/files`,
  getAllUsers: `/dtms/api/v1/dtms/user/users`,
  getSignedThirdParties: `/dtms/api/v1/tpra/signedThirdParties`,
  viewFile: `/dtms/api/v1/tpra/view`,
  uploadFile: `/dtms/api/v1/tpra/upload`,
  downloadFile: `/dtms/api/v1/tpra/download`,
  shareFile: `/dtms/api/v1/tpra/share`,
  editPdf: `/dtms/api/v1/tpra/edit/file`,
  approveFile: `/dtms/api/v1/tpra/approve`,
  rejectFile: `/dtms/api/v1/tpra/reject`,
  deleteUser: `/dtms/api/v1/dtms/user/delete`,
  editUserRoles: `/dtms/api/v1/dtms/user/role/update`,
  editTprlaUserRoles: `/dtms/api/v1/tpra/users`,
  getPublicModules: `/dtms/api/v1/dtms/public/modules`,
  getModuleRoles: `/dtms/api/v1/dtms/user/moduleRoles`,
  getPublicModuleRoles: `/dtms/api/v1/dtms/user/publicRoles`,
  getAccessibleModules: `/dtms/api/v1/dtms/user/selfDetails`,
  updatePublicModuleAccess: `/dtms/api/v1/dtms/user/addPublicModuleAccess`,
  selfInvite: `/auth/api/v1/dtms/auth/self-inviteUser`,
  getProjects: `/dtms/api/v1/tpra/projects`,
  createProject: `/dtms/api/v1/tpra/project`,
  deleteProject: `/dtms/api/v1/tpra/project`,
  refreshSession: `/dtms/api/v1/dtms/user/refreshSession`,
  createTprlaUser: `/dtms/api/v1/tpra/users`,
  deleteTprlaUser: `/dtms//api/v1/tpra/delete/user`,
  deleteTprlaFile: `/dtms/api/v1/tpra/file`,
  getReports: `/dtms/api/v1/tpra/getReports`,
  comment: `/dtms/api/v1/tpra/comments`,
  registerEsraUser: `/productIAM/register`,
  getAllSglProjects: `/sgl/api/v1/sgl/mgmt/filter/projects`,
  getSglProjectDetails: `/sgl/api/v1/sgl/mgmt/project`,
  manageSglProject: `/sgl/api/v1/sgl/mgmt/project`,
  getSglUsers: `/sgl/api/v1/sgl/mgmt/users`,
  manageSglUser: `/sgl/api/v1/sgl/mgmt/user`,
  getAllSglFiles: `/storage/api/storage-service/sharepoint/list`,
  saveSpAuthCode: `/storage/api/storage-service/sharepoint/auth/token`,
  getSglReportStatus: `/sgl/api/v1/sgl/mgmt/result`,
  generateSglReport: `/sgl/api/v1/sgl/mgmt/request/report`,
  getFileDownload: `/storage/api/storage-service/sharepoint/download-bulk`,
  downloadSglReport: `/storage/api/storage-service/sharepoint/download`,
  dmtPostToken: `/connect/token`,
  dmtGetTemplate: `/api/loginhandler/gettemplates`,
  dmtAssessment: `/api/assessment`,
  dmtCompletedAssessment: `/api/Assessment`,
  dmtEditQuantitative: `api/assessment`,
  dmtClients: `/api/loginhandler/getclients`,
};

export default api;
