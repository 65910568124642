import { createSlice } from "@reduxjs/toolkit";
import { _getLoggedInUserDetails } from "../../../envUrl";
import _ from "lodash";

const dmaAssessmentSlice = createSlice({
  name: "dmaAssessment",
  initialState: { assessmentDetails: {}, isAssessmentActive: false },
  reducers: {
    setIsAssessmentActive(state, action) {
      state.isAssessmentActive = action.payload;
    },
    setDmaAssessmentDetails(state, action) {
      state.assessmentDetails = action.payload;
    },
  },
});

export const { setDmaAssessmentDetails, setIsAssessmentActive } =
  dmaAssessmentSlice.actions;
export default dmaAssessmentSlice.reducer;
